
import { Component, Vue } from 'vue-property-decorator';
import { Menu, StartPage, TestTypeItem } from '@/store/layout-store/types';
import { NeIcon } from '@ne/ne-vue2-lib';
import HomeLoggedSeries from '@/views/Home/HomeLogged/HomeLoggedSeries/HomeLoggedSeries.vue';
import PageContent from '@/shared/components/PageContent/PageContent.vue';
import { LoadingStatus } from '@/shared/models/loading';
import { mulGtContents } from '@/api/config';
import DynamicWidget from '@/shared/components/DynamicWidget/DynamicWidget.vue';
import { OneColumnPage } from '@/models/widgets';
import { namespace } from 'vuex-class';
import { TenantsUrl } from '@/store/app-store/types';
import { SET_FILTERS_ACTIVE } from '@/store/list';
import { SppName } from '@/config';

export interface OpenClass {
  key: string;
  classId: number | undefined;
  className: string;
  classKey: string;
}
const AppStore = namespace('AppStore');

@Component({
  name: 'HomeLogged',
  components: {
    DynamicWidget,
    PageContent,
    HomeLoggedSeries,
    NeIcon
  }
})
export default class HomeLogged extends Vue {
  items: Menu[] = [];
  oneColumnPage: OneColumnPage | null = null;
  openClassArr: OpenClass[] = [];
  loadingStatus = LoadingStatus.INCOMPLETE;
  testType: TestTypeItem = {};

  sppName = SppName;

  @AppStore.State('tenantsUrl') tenantsUrl: TenantsUrl;
  @AppStore.Mutation(SET_FILTERS_ACTIVE) setFiltersActive: (isFiltersActive: boolean) => void;

  get subjectsUrl (): string {
    return this.tenantsUrl.mne + '/panel/szkola';
  }

  getEducationName (educationName: string): string {
    return educationName.toUpperCase().replace(/(\d+|KLASY|–)\s?/g, '').trim();
  }

  isContainerVisible (key: string): boolean {
    return this.openClassArr.find(item => item.key === key) !== undefined;
  }

  openClass ({ key, classId, className, classKey }: OpenClass): void {
    const item = this.openClassArr.find(item => item.key === key);
    if (item) {
      item.classId = classId;
      item.classKey = classKey;
      item.className = className;
    } else {
      this.openClassArr.push({ key, classId, className, classKey });
    }
  }

  async created () {
    this.setFiltersActive(true);
    const startPage = (await this.$api.get('startPage')).data as StartPage;
    [this.items, this.testType] = [startPage.class, startPage.testType];
    this.loadingStatus = LoadingStatus.COMPLETE;
    this.oneColumnPage = (await this.$apiMul.get<OneColumnPage>(`${mulGtContents}/twoje-przedmioty/cyfrowe-wsparcie-nauczycieli`)).data;
  }
}
