
import { Component, Vue } from 'vue-property-decorator';
import {
  DynamicWidget
} from '@/shared/components';
import { mulGtHomePage } from '@/api/config';
import { BasePage, HomePage, PageSections, Widget, Widgets } from '@ne/ne-mul-interface-api';
import { NeLayoutHomePage } from '@ne/ne-vue2-lib';
import { namespace } from 'vuex-class';
import { LayoutModel } from '@/store/layout-store/types';
import HomeLogged from '@/views/Home/HomeLogged/HomeLogged.vue';
import { LoginStatus } from '@/models';
import { ROLES } from '@/config';
import { isSuperUser } from '@/helpers/roles';

const LayoutStore = namespace('LayoutStore');
const AppStore = namespace('AppStore');

const widgetHomePageResponsiveOptionsMock = [
  {
    breakpoint: 360,
    itemsToShow: 5
  },

  {
    breakpoint: 375,
    itemsToShow: 1
  },

  {
    breakpoint: 425,
    itemsToShow: 2
  },

  {
    breakpoint: 768,
    itemsToShow: 3
  },

  {
    breakpoint: 992,
    itemsToShow: 2
  },

  {
    breakpoint: 1200,
    itemsToShow: 3
  }
];

@Component({
  components: {
    DynamicWidget,
    NeLayoutHomePage,
    HomeLogged
  }
})
export default class Home extends Vue {
  homePageData: BasePage | null = null;
  widgets = [] as Widget<Widgets>[];
  topSections: Widget<Widgets>[] | undefined;

  @LayoutStore.State('layout') layout: LayoutModel;
  @AppStore.State('loginStatus') loginStatus: null | LoginStatus;
  @AppStore.State('userRole') userRole: ROLES[];

  get isUserLogged (): boolean {
    return !!this.layout.user;
  }

  async mounted (): Promise<void> {
    await this.fetchHomePage();
  }

  async fetchHomePage (): Promise<void> {
    this.homePageData = (await this.$apiMul.get<BasePage>(mulGtHomePage)).data;
    this.widgets = (this.homePageData.sections as PageSections).content;
    this.topSections = (this.homePageData.sections as PageSections).top ? (this.homePageData.sections as PageSections).top : [];
  }

  get responsiveOptions () {
    return widgetHomePageResponsiveOptionsMock;
  }

  get sliderSection () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.sliderSection || [];
  }

  get brandingSection () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.brandingSection || [];
  }

  get description () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.description || '';
  }

  get background () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.backgroundColor || null;
  }

  get displayStyle () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.displayStyle || '';
  }

  get accentColor () {
    return (this.topSections![0] as Widget<HomePage>)?.attributes?.accentColor || '';
  }
}
