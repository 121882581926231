
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NeIcon } from '@ne/ne-vue2-lib';
import { Menu, TestTypeItem } from '@/store/layout-store/types';
import { RouteName } from '@/router/models';
import { OpenClass } from '@/views/Home/HomeLogged/HomeLogged.vue';
import { Tooltip } from '@/shared/components';
import { RawLocation } from 'vue-router/types/router';
import { StructureStatusName, TestTypeIdEnum } from '@/models';
import { namespace } from 'vuex-class';

const AppStore = namespace('AppStore');

@Component({
  name: 'HomeLoggedSeries',
  components: {
    Tooltip,
    NeIcon
  }
})
export default class HomeLoggedSeries extends Vue {
  @Prop() isSpp: boolean;
  @Prop() openClassArr: OpenClass[];
  @Prop() series: Menu
  @Prop() subject: Menu
  @Prop() testTypes: TestTypeItem

  testTypeIdEnum = TestTypeIdEnum;
  routeName = RouteName;

  @AppStore.State('structureStatus') structureStatus!: StructureStatusName;

  getClassItemClass (series: Menu, classItem: Menu): Object {
    return {
      'gt-home-logged-series__class-item--active': this.isContainerVisible(series.key) && this.openClassArr.find(item => item.key === series.key)?.classId === classItem.id,
      'gt-home-logged-series__class-item--new-structure': classItem.label !== null,
      'gt-home-logged-series__class-item--new-structure-disabled': !classItem.access && classItem.label !== null
    };
  }

  getLogoDescription (subject: Menu, series: Menu): string {
    const pattern = /ZP|ZR|ZPiR|BS I/g;
    const index = series.name.search(pattern);
    if (index === -1) return subject.name;
    return subject.name + ' ' + series.name.slice(index);
  }

  get classes (): Menu[] {
    return this.series.children!.filter(item => {
      return this.isMenuLinkDisplayed(item);
    });
  }

  goTo (seriesKey: string, routeName: RouteName, testTypeId?: string): void {
    localStorage.removeItem('activeFacets');
    const item = this.openClassArr.find(item => item.key === seriesKey);
    if (item) {
      const rawLocation: RawLocation = {
        name: routeName,
        params: {
          categoryKey: item.classKey.split('/').join(','),
          testTypeId: testTypeId && routeName === RouteName.TEST_COMPOSER ? testTypeId : ''
        },
        query: routeName === RouteName.TEST_DATABASE ? { facets: `testType-${testTypeId}` } : { }
      };
      this.$router.push(rawLocation);
      window.scrollTo({ top: 0, left: 0 });
    }
  }

  get testTypeArr (): string[] {
    return Object.keys(this.testTypes).reverse().filter(item => !this.isSpp || +item !== TestTypeIdEnum.WORK_CARD);
  }

  get hasSeriesClassWithNewEdition (): Menu | undefined {
    let item;
    if (this.series && this.series.children && this.series.children.length) item = this.series.children.find(classItem => classItem.label !== null);
    return item;
  }

  isContainerVisible (key: string): boolean {
    return this.openClassArr.find(item => item.key === key) !== undefined;
  }

  isMenuLinkDisplayed (pill: Menu): boolean {
    return pill.newStructure || !pill.mapCategoryId;
  }

  @Emit() onClassOpen (item: OpenClass): OpenClass {
    return item;
  }
}
